<template>
  <v-btn
    block
    @click="handleExportPdf"
    color="primary"
  >
    Exportar PDF
  </v-btn>
</template>
<script>
import api from '@/services/api'
export default {
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
    query: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    count: null,
    reservations: null,
  }),
  methods: {
    handleExportPdf () {
      api.getAll ('booking', `v1/private/workspaces/${this.workspaceID}/reservations/pdf`, 0, 1000000, this.query)
        .then(response => {
          //console.log(response)
          // window.location.href = response
          window.open(response, '_blank')
        })
//      this.handleGetData()
    },
    /*handleGetData () {
      this.loading = true

      const query = `${this.search ? this.search : ''}&date=${this.selectedDate}`
      api.getAll ('booking', `v1/private/workspaces/${this.workspaceID}/reservations`, 0, 1000000, query)
        .then(response => {
          this.count = response.count
          this.reservations = response.data.map(item => {
            return {
              ident: {
                id: item.ID,
                name: `
                        <p>${item.OwnerName} ${item.OwnerLastName ? item.OwnerLastName : ''}</p>
                        <p>${item.OwnerEmail}</p>
                      `,
              },
              ID: item.ID,
              ActivityName: `${item.Source === 'acttiv' ? '⚠ ' : '' }` + utils.getTranslation(item.ActivityName, this.locale),
              Time: utils.convertTime(item.Time),
              Owner: `${item.OwnerName} ${item.OwnerLastName ? item.OwnerLastName : ''}`,
              Email: item.OwnerEmail,
              NotEditable: item.Source === 'acttiv' && item.Status === 'CANCELED',
              Status: item.Status,
              Event: {
                IsActtiv: item.Source === 'acttiv',
                ID: item.BookedEventID,
                Source: item.Source,
                WorkspaceID: item.WorkspaceID,
              },


              NumPax: item.NumPax,
              FieldsValues: item.FieldsValues ? JSON.parse(item.FieldsValues) : null,
              IsActtiv: item.Source === 'acttiv',
              CU: item.CU,
              LMU: item.LMU,
            }
          })
          this.loading = false
          console.log(this.reservations)
        })
    },*/
  },
}
</script>

